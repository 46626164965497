// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,800,900');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Daterangepicker
@import '~daterangepicker/daterangepicker';
// @import '~bootstrap-icons/font/bootstrap-icons';
// @import '~bootstrap-select/sass/bootstrap-select';

